.toolbar {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toolbar_page-tabs {
  margin-top: 25px;
}

.toolbar__item {
  display: flex;
  align-items: baseline;
}

.toolbar__space {
  width: 40px;
  height: 1px;
}
