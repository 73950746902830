@import "values";

.checkbox {
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
}

.checkbox_input {
  opacity: 0;
  width: 0;
  height: 0;
  user-select: none;
}

.checkbox_label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  display: inline-block;
  height: 22px;
  line-height: 22px;
  user-select: none;
}

.checkbox_label:after, .checkbox_label:before {
  content: "";
  left: 0;
  position: absolute;
}

.checkbox_label:after {
  border-radius: 3px;
  transition: background 0.5s ease, border 0.5s ease;
}

.checkbox_label:before {
  top: 5px;
  left: 5px;
  width: 12px;
  height: 12px;
  border-radius: 3px;
  opacity: 0;
  background: $checkbox-color;
  transition: opacity 0.5s ease;
  z-index: 2;
}

.checkbox_input:checked + .checkbox_label:after {
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid $checkbox-border-color;
  z-index: 0;
}

.checkbox_input:not(:checked) + .checkbox_label:after {
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 1px solid $checkbox-border-color;
  top: 0;
  z-index: 0;
}

.checkbox_input:checked + .checkbox_label:before {
  opacity: 1;
}

.checkbox_input:focus + .checkbox_label:after {
  border: 1px solid $checkbox-focus-border-color;
}

.checkbox_input:active + .checkbox_label:after {
  border-color: $checkbox-active-border-color;
  background-color: $checkbox-active-background;
}

.checkbox_input:active + .checkbox_label:before {
  z-index: 1;
}

.checkbox_input:hover + .checkbox_label:after {
  border-color: $checkbox-hover-border-color;
}

.checkbox_input:hover:focus + .checkbox_label:after {
  border-color: $checkbox-focus-border-color;
}

.checkbox_input[disabled] + .checkbox_label {
  cursor: default;
}

.checkbox_input[disabled] + .checkbox_label:after {
  border-color: $checkbox-disabled-border-color;
  background: $checkbox-disabled-background;
}

.checkbox_input[disabled]:checked + .checkbox_label:before {
  background-color: $checkbox-disabled-color;
}

.checkbox_input:checked:not(:disabled) ~ .checkbox_label:active:after,
.checkbox_input:not(:disabled) ~ .checkbox_label:active:after {
  border-color: $checkbox-active-border-color;
  background-color: $checkbox-active-background;
}
