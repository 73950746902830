@import "values";

.popup-menu {
  min-width: 70px;
  color: $pop-up-menu-item-color;
}

.popup-menu__item {
  text-decoration: none;
  color: inherit;
  display: block;
  padding: 5px 10px;
  margin: 7px 0;
  outline: none;

  &:hover {
    background: $pop-up-menu-item-hover-background;
    color: inherit;
  }

  &:focus {
    box-shadow: $border-color-active 0 0 0 2px inset;
  }
}

.popup-menu__item-text {
  white-space: nowrap;
}

.popup-menu__item-text-info {
  display: block;
  font-size: 0.8em;
  white-space: nowrap;
  color: $dark-gray-text-color;
}
