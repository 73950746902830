@import "values";

.user-menu__icon {
  background: $user-menu-icon-background;
  width: 43px;
  height: 43px;
  border-radius: 40px;
  cursor: pointer;
  text-align: center;
  opacity: 0.8;
  margin: 4px;
  padding: 6px 0 0 0;
}

.user-menu__button {
  width: 51px;
  height: 51px;
  padding: 0;
  text-align: center;
  line-height: 0;
  border-radius: 30px;
  margin-bottom: 15px;
  background: transparent;
  border: none;
  outline: none;

  &:focus {
    box-shadow: $border-color-active 0 0 0 2px inset;
  }

  &:hover {
    opacity: 0.8;
  }
}

.user-menu__wrapper {
  color: $pop-up-menu-item-color;
}

.user-menu__login {
  padding: 7px 10px 5px 10px;
  color: $pop-up-menu-item-muted-color;
  margin-bottom: 5px;
  border-radius: 4px 4px 0 0;
}

.user-menu__languages {
  display: flex;
  align-items: baseline;
  margin: 0 10px;
}

.user-menu__language-label {
  margin: 0 15px 0 0;
}

.user-menu__language {
  margin: 0 2px;
  font-size: 12px;
}

.user-menu__item {
  text-decoration: none;
  color: inherit;
  display: block;
  padding: 5px 10px;
  margin: 7px 0;
  outline: none;

  &:hover {
    background: $pop-up-menu-item-hover-background;
    color: inherit;
  }

  &:focus {
    box-shadow: $border-color-active 0 0 0 2px inset;
  }
}