@import "values";

.radio-button-list {
  display: flex;
  position: relative;
  margin: $form-control-padding;
}

.radio-button-list__direction_column {
  flex-direction: column;
}

.radio-button-list__direction_column .radio-button + .radio-button {
  margin-top: 10px;
}

.radio-button-list__error {
  font-size: 0.77rem;
  color: $field-error-color;
  display: block;
  position: absolute;
  bottom: -20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}