@import "values";

.date-interval {
  display: flex;
  align-items: baseline;
}

.date-interval_filter {
  margin: $filter-control-padding;
}

.date-interval__separator {
  margin: 0 10px;
}
