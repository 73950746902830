@import "values";

@mixin modalBackgroundToggle($name, $opacityFrom, $opacityTo) {
  @keyframes #{$name}Show {
    from {
      opacity: $opacityFrom;
    }
    to {
      opacity: $opacityTo;
    }
  }

  @keyframes #{$name}Hide {
    from {
      opacity: $opacityTo;
      visibility: visible;
    }
    to {
      opacity: $opacityFrom;
      visibility: hidden;
    }
  }
}

@include modalBackgroundToggle(modalBackgroundHalf, 0, 0.5);
@include modalBackgroundToggle(modalBackgroundFull, 0, 1);

.modal-background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 95;
  visibility: hidden;
  opacity: 0;
  background: $modal-background;
}

.modal-background_white {
  background: #fff;
}

.modal-background_visible {
  opacity: 0.5;
  visibility: visible;
  animation: modalBackgroundHalfShow 0.2s;
}

.modal-background_hidden {
  animation: modalBackgroundHalfHide 0.2s;
}

.modal-background_white {
  &.modal-background_visible{
    opacity: 1;
    animation: modalBackgroundFullShow 0.2s;
  }

  &.modal-background_hidden {
    animation: modalBackgroundFullHide 0.2s;
  }
}
