.functionality {
  margin: 0 20px 25px;
  width: 100%;
}

.functionality__header {
  margin-bottom: 15px;
}

.functionality__title {
  font-size: 1.2rem;
  margin-right: 5px;
  font-weight: bold;
  display: inline-block;
}

.functionality__list {
  display: flex;
  flex-wrap: wrap;
}

.functionality__item {
  width: 25%;
  min-width: 70px;
  padding: 0 10px 10px 0;
}
