.fieldset {
  border-radius: 4px;
  padding: 15px 0 0 0;
  margin: 0 20px 20px;
  border: 1px solid #e5e5e5;
}

.fieldset__legend {
  font-size: 1.4rem;
  font-weight: bold;
  display: inline-block;
  margin: 0 20px 15px;
}