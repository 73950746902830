.chip-list {
  display: flex;
  flex-wrap: wrap;
  margin: -2px;
  overflow: hidden;
}

.chip-list__item {
  margin: 2px;
  white-space: nowrap;
  max-width: 100%;
}
