.tooltip {
  position: relative;
  line-height: 0;
}

.tooltip__content {
  position: absolute;
  line-height: 1.65em;
  z-index: 10;
  margin-top: 5px;
  right: 0;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 8px 17px 0 rgba(0,0,0,0.15), 0 6px 20px 0 rgba(0,0,0,0.08);
}

.tooltip__content_filter {
  padding: 10px 15px;
  width: 425px;
}

.tooltip__content_user-menu {
  left: 55px;
  right: auto;
  bottom: 20px;
}

.tooltip__content_enter {
  margin-top: -5px;
  opacity: 0.01;
}

.tooltip__content_enter.tooltip__content_enter_active {
  margin-top: 5px;
  opacity: 1;
  transition: opacity 200ms ease-in-out, margin 200ms ease-in-out;
}

.tooltip__content_leave {
  margin-top: 5px;
  opacity: 1;
}

.tooltip__content_leave.tooltip__content_leave_active {
  margin-top: -5px;
  opacity: 0.01;
  transition: opacity 200ms ease-in-out, margin 200ms ease-in-out;
}
