@import "values";

.Select-control {
  height: 32px;
  border-color: $border-color-dark;
}

.Select-input {
  height: 30px;
}

.Select-menu-outer {
  z-index: 10;
}

.Select-arrow-button {
  fill: $dark-gray-text-color;
  display: flex;
  width: 24px;
  height: 24px;
  transition: transform 0.2s ease-out;
}

.Select-arrow-button_open {
  transform: rotate(180deg);
}

.Select-placeholder, .Select--single > .Select-control .Select-value {
  line-height: 30px;
}

.Select.is-focused:not(.is-open) > .Select-control {
  border-color: $border-color-active;
  box-shadow: none;
  transition: all 0.5s ease;
}

.Select.is-disabled > .Select-control {
  background: $field-disabled-background;
  border: 1px solid $field-disabled-border-color;
}

.Select.Select--single.is-disabled > .Select-control .Select-value .Select-value-label,
.Select.has-value.Select--single.is-disabled > .Select-control .Select-value .Select-value-label {
  color: $field-disabled-color;
  user-select: none;
}

.select_form {
  margin: $form-control-padding;
}

.select_filter-padding {
  margin: $filter-control-padding;
}

.select_error {
  .Select-control {
    border-color: $field-error-border-color;
  }
}

.select-component {
  position: relative;
}

.select-component__error {
  font-size: 0.77rem;
  color: $field-error-color;
  display: block;
  position: absolute;
  bottom: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.select-component__error_support-period {
  bottom: -20px;
}
