.react-datepicker {
  border: none;
  font-size: 1rem;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.08);
}

.react-datepicker__header {
  background: #473387;
  border-bottom: 1px solid #473487;
}

.react-datepicker__day,
.react-datepicker__time-name {
  color: #333;
}

.react-datepicker__day-name,
.react-datepicker__current-month {
  color: #e9e5f5;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-top-color: #ebebeb;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: #483783;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: transparent;
}

.react-datepicker__navigation--previous {
  border-right-color: #e9e5f5;
}

.react-datepicker__navigation--next {
  border-left-color: #e9e5f5;
}

.react-datepicker__day--outside-month {
  color: #ccc;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: #c72d7e;
  color: #fff;
}

.react-datepicker__day--keyboard-selected {
  background-color: #c71f78;
  color: #fff;

  &:hover {
    background-color: #c71f78;
  }
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background-color: rgba(199, 45, 126, 0.2);
}

.react-datepicker__day--selecting-range-start {
  border-radius: 4px 0 0 4px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2rem;
  line-height: 2rem;
}

.react-datepicker__month {
  margin: 0.6rem;
}

.react-datepicker-popper {
  z-index: 3;
}

.react-datepicker-popper_triangle_right .react-datepicker__triangle {
  left: auto;
  right: 50px;
}

.react-datepicker__day--in-range {
  background-color: rgba(199, 45, 126, 0.12);
  color: #920b51;
  margin: 0;
  padding: 0.166rem;
  border-radius: 0;
  width: 2.332rem;
}

.react-datepicker__day--range-start {
  background-color: #c71f78;
  color: #fff;
  border-radius: 4px 0 0 4px;
}

.react-datepicker__day--range-end {
  background-color: #c71f78;
  color: #fff;
  border-radius: 0 4px 4px 0;
}

.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start {
  background: #c71f78;
  border-radius: 4px 0 0 4px;
  color: #fff;
}

.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end {
  background: #c71f78;
  border-radius: 0 4px 4px 0;
  color: #fff;
}

.react-datepicker__day--range-start.react-datepicker__day--range-end {
  border-radius: 4px;
}

.react-datepicker__day--today {
  font-weight: normal;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover {
  color: #fff;
  background-color: #c82077;
}

.react-datepicker__day--in-selecting-range {
  background-color: rgba(199, 45, 126, 0.12);
  color: #920b51;
  margin: 0;
  padding: 0.166rem;
  border-radius: 0;
  width: 2.332rem;
}

.react-datepicker__footer {
  margin: 1rem;
}
