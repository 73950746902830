@import "values";

.empty-table {
  width: 100%;
  padding: 50px;
  text-align: center;
}

.empty-table__title {
  font-size: 24px;
  color: $empty-table-text-color;
  padding-bottom: 15px;
}

.empty-table__text {
  font-size: 18px;
  color: $empty-table-text-color;
}
