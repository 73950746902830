@import "values";

.radio-button {
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
}

.radio-button_input {
  opacity: 0;
  width: 0;
  height: 0;
  user-select: none;
}

.radio-button_label {
  position: relative;
  padding-left: 30px;
  padding-right: 15px;
  cursor: pointer;
  display: inline-block;
  height: 22px;
  line-height: 22px;
  user-select: none;
}

.radio-button_label:after, .radio-button_label:before {
  content: "";
  left: 0;
  position: absolute;
  display: inline-block;
}

.radio-button_label:after {
  border-radius: 50%;
  transition: background 0.5s ease, border 0.5s ease;
}

.radio-button_label:before {
  top: 5px;
  left: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  opacity: 0;
  background: $checkbox-color;
  transition: opacity 0.5s ease;
  z-index: 2;
}

.radio-button_input:checked + .radio-button_label:after {
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid $checkbox-border-color;
  z-index: 0;
}

.radio-button_input:not(:checked) + .radio-button_label:after {
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 1px solid $checkbox-border-color;
  top: 0;
  z-index: 0;
}

.radio-button_input:checked + .radio-button_label:before {
  opacity: 1;
}

.radio-button_input:focus + .radio-button_label:after {
  border: 1px solid $checkbox-focus-border-color;
}

.radio-button_input:active + .radio-button_label:after {
  border-color: $checkbox-active-border-color;
  background-color: $checkbox-active-background;
}

.radio-button_input:active + .radio-button_label:before {
  z-index: 1;
}

.radio-button_input:hover + .radio-button_label:after {
  border-color: $checkbox-hover-border-color;
}

.radio-button_input:hover:focus + .radio-button_label:after {
  border-color: $checkbox-focus-border-color;
}

.radio-button_input[disabled] + .radio-button_label {
  cursor: default;
}

.radio-button_input[disabled] + .radio-button_label:after {
  border-color: $checkbox-disabled-border-color;
  background: $checkbox-disabled-background;
}

.radio-button_input[disabled]:checked + .radio-button_label:before {
  background-color: $checkbox-disabled-color;
}

.radio-button_input:checked:not(:disabled) ~ .radio-button_label:active:after,
.radio-button_input:not(:disabled) ~ .radio-button_label:active:after {
  border-color: $checkbox-active-border-color;
  background-color: $checkbox-active-background;
}

.radio-button_input__error:not(:checked) + .radio-button_label:after {
  border-color: $field-error-border-color;
}
