@import "values";

.input-component {
  position: relative;
  width: 100%;
}

.input-component__error {
  font-size: 0.77rem;
  color: $field-error-color;
  display: block;
  position: absolute;
  bottom: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.input-component__error_price {
  bottom: -20px;
}

.input-component__field_error {
  border-color: $field-error-border-color;
}
