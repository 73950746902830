@import "values";

.table {
  display: flex;
  flex-direction: column;
  margin-right: -$table-offset;
  margin-left: -$table-offset;
  width: 100%;
  flex: 0 1 auto;
}

.table_modal {
  margin: 0;
}

.table__header-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  width: 100%;
  cursor: default;
  user-select: none;
}

.table__header-text_sorted {
  cursor: pointer;

  &:hover {
    color: $table-header-hover-color;
  }

  &:after {
    content: '';
    display: inline;
    padding-left: 4px;
    font-size: 1.2rem;
  }
}

.table__header-text_sorted_asc:after {
  content: 'â';
}

.table__header-text_sorted_desc:after {
  content: 'â';
}

.table__body {
  display: flex;
  flex-direction: column;
  margin: 0 -$table-offset;
}

.table__cell {
  padding: 8px $table-offset;
  display: flex;
  flex: 1 0 auto;
  align-items: flex-start;
  min-height: 39px;
}

@for $i from 1 through 24 {
  .table__cell_#{$i} {
    $width: $i * 100% / $table-columns;
    flex-basis: $width;
    max-width: $width;
  }
}

.table__cell_has-action-button {
  $right-offset: $table-offset + $table-action-button-width;
  padding-right: $right-offset;
}

.table__cell_action-button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0;
  width: $table-action-button-width;
  max-width: $table-action-button-width;
  align-items: center;
}

.table__row {
  display: flex;
  position: relative;
  border-bottom: 1px solid $table-row-border-color;

  &:hover {
    background: $table-body-hover-background;
  }
}

.table__row_header {
  border-bottom: 2px solid $table-header-border-color;
}

.table__row_header,
.table__row_empty {
  &:hover {
    background: transparent;
  }
}

.table__row_interactive {
  cursor: pointer;
}

.table__pagination {
  color: $gray-text-color;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0 10px;
}

.table__pagination-count {
  padding: 0 15px;
}

.table__pagination-count-value {
  color: $dark-gray-text-color;
}

.table__pagination-buttons {
  display: flex;
}

.table__pagination-button {
  margin-right: 10px;

  &:last-child{
    margin-right: 0;
  }
}

.table__pagination-per-page {
  display: flex;
  align-items: center;
}

.table__pagination-per-page-title {
  padding-right: 10px;
}

.table__pagination-per-page-value {
  width: 60px;
}
