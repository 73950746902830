@import "values";
@import "mixins";

input::placeholder,
textarea::placeholder {
  color: $field-placeholder-color;
}

.form__row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
}

@for $i from 1 through 4 {
  .form__row_#{$i} {
    $width: ($i * $form-field-width) + ($i * 40);
    flex-basis: $width;
    max-width: $width;
  }
}

.form__row_footer {
  flex-direction: row-reverse;
}

.form__row_hardware-key {
  $width: $form-field-width * 2 + 40;
  width: $width;
  max-width: $width;
  background: #ebebeb6e;
  border-radius: 4px;
  padding: 15px 0 20px 0;
  margin: 0 0 20px;
}

.form__field {
  display: flex;
  flex-direction: column;
  width: $form-field-width;
  margin: 0 20px;
  align-self: flex-end;
}

.form__field_auto {
  width: auto;
}

.form__label {
  line-height: 1.4rem;
}

.form__label-description {
  color: $gray-text-color;
}

.form__label_required { @include required-asterisk; }

.form__field_wide {
  width: 100%;
}

.form__field-text {
  border: 1px solid $field-border-color;
  border-radius: 5px;
  padding: 8px 9px;
  width: 100%;
  transition: border-color 0.5s ease;

  &:not([tabIndex="-1"]):focus {
    border: 1px solid $border-color-active;
    outline: none;
  }

  &:disabled {
    background: $field-disabled-background !important;
    border: 1px solid $field-disabled-border-color !important;
    color: $field-disabled-color !important;
    resize: none;
    cursor: default;
  }
}

.form__field-checkbox {
  margin-bottom: 20px;
}

.form__field-text_padding {
  margin: $form-control-padding;
}

.form__field-text_filter-padding {
  margin: $filter-control-padding;
}

.form__field-text_currency {
  padding-right: 90px;
}

.form__field_width_auto {
  width: auto;
}

.form__footer {
  padding: 8px 20px  0 20px;
  display: flex;
  flex-direction: row-reverse;
  border-top: 1px solid $border-color-light;
  margin: 15px -20px 0 -20px;
}

.form_auth {
  width: 312px;
  margin: 0 auto;
  background: #fff;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.03) -1px 4px 10px 0px;
  border-radius: 4px;
  border: 1px solid $form-auth-border-color;
  padding: 15px 20px 0 20px;
}

.form-line {
  height: 1px;
  width: 100%;
  margin: 20px 20px 25px;
  background: $border-color-light;
}

.form-line_filter {
  margin: 15px 0 15px;
}

.form_filter {
  margin: 20px 20px 0;
}

.form-line__title {
  font-size: 1.2rem;
  font-weight: bold;
  transform: translateY(-50%);
  background: #fff;
  padding-right: 15px;
  position: absolute;
}