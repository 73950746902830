@import "values";

.sidebar__menu {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  font-size: 12px;
  margin-top: 30px;
}

.sidebar__menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  min-height: 64px;
  border-radius: 4px;
  border: none;
  color: $sidebar-menu-item-color;
  cursor: pointer;
  padding: 0;
  outline: 0;
  text-decoration: none;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 10px;

  &:hover {
    color: $sidebar-menu-item-hover-color;
    opacity: 0.8;
  }

  &:focus {
    box-shadow: $border-color-active 0 0 0 2px inset;
  }
}

.sidebar__menu-icon {
  display: flex;
  padding: 10px 10px 6px;
}

.sidebar__menu-text {
  padding-bottom: 6px;
  justify-content: left;
  width: 90%;
  font-size: 11px;
  line-height: 1.15;
  text-align: center;
  overflow: hidden;
}

.sidebar__menu-item_active {
  background: $sidebar-menu-item-active-background;

  .sidebar__menu-icon,
  .sidebar__menu-text {
    opacity: 0.8;
  }
}
