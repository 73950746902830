@import "values";

.button {
  color: $button-color;
  background: $button-background;
  cursor: pointer;
  line-height: normal;
  border: 1px solid $button-border-color;
  border-radius: 4px;
  padding: 7px 15px;
  outline: none;
  text-decoration: none;
  transition: all 0.5s ease;
  white-space: nowrap;

  &:not(.button_non-interactive):focus,
  &:not(.button_non-interactive):focus:hover {
    border-color: $button-focus-border-color;
    z-index: 1;
  }

  &:not(.button_non-interactive):hover {
    border-color: $button-hover-border-color;
    z-index: 1;
  }

  &:not(.button_non-interactive):active {
    border-color: $button-active-border-color;
    background: $button-active-background;
    z-index: 1;
  }
}

.button:disabled {
  background: $field-disabled-background !important;
  border: 1px solid $field-disabled-border-color !important;
  color: $field-disabled-color !important;
  resize: none;
  cursor: default;
}

.button_form {
  margin: $form-control-padding;
}

.button_wide {
  width: 100%;
}

.button_submit,
.button_action {
  fill: #fff;
  color: $button-submit-color;
  background: $button-submit-background;
  // ÐÐµÐ»Ð°ÐµÐ¼ Ð³ÑÐ°Ð½Ð¸ÑÑ Ð½ÐµÐ·Ð°Ð¼ÐµÑÐ½Ð¾Ð¹
  border-color: $button-submit-background;

  &:not(.button_non-interactive):hover,
  &:not(.button_non-interactive):focus,
  &:not(.button_non-interactive):focus:hover {
    color: $button-submit-color;
    background: $button-submit-focus-background;
    // ÐÐµÐ»Ð°ÐµÐ¼ Ð³ÑÐ°Ð½Ð¸ÑÑ Ð½ÐµÐ·Ð°Ð¼ÐµÑÐ½Ð¾Ð¹
    border-color: $button-submit-focus-background;
  }

  &:not(.button_non-interactive):active {
    color: $button-submit-color;
    background: $button-submit-active-background;
    // ÐÐµÐ»Ð°ÐµÐ¼ Ð³ÑÐ°Ð½Ð¸ÑÑ Ð½ÐµÐ·Ð°Ð¼ÐµÑÐ½Ð¾Ð¹
    border-color: $button-submit-active-background;
  }
}

.button_non-interactive {
  cursor: default;
}

.button_currency {
  padding: 3px 7px 4px 7px;
  margin: 4px 2px;
  background: $button-currency-background;
  // ÐÐµÐ»Ð°ÐµÐ¼ Ð³ÑÐ°Ð½Ð¸ÑÑ Ð½ÐµÐ·Ð°Ð¼ÐµÑÐ½Ð¾Ð¹
  border-color: $button-currency-background;
}

.button_currency-active {
  color: $button-currency-active-color;
  background: $button-currency-active-background;
  border-color: $button-currency-active-background;

  &:hover,
  &:focus,
  &:focus:hover {
    background: $button-currency-active-focus-background;
    // ÐÐµÐ»Ð°ÐµÐ¼ Ð³ÑÐ°Ð½Ð¸ÑÑ Ð½ÐµÐ·Ð°Ð¼ÐµÑÐ½Ð¾Ð¹
    border-color: $button-currency-active-focus-background;
  }

  &:active {
    background: $button-currency-active-active-background;
    // ÐÐµÐ»Ð°ÐµÐ¼ Ð³ÑÐ°Ð½Ð¸ÑÑ Ð½ÐµÐ·Ð°Ð¼ÐµÑÐ½Ð¾Ð¹
    border-color: $button-currency-active-active-background;
  }

  &:disabled {
    opacity: 0.7;
    color: $button-currency-active-color !important;
    background: $button-currency-active-background !important;
    border-color: $button-currency-active-background !important;
  }
}

.button_icon {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 31px;
}

.button_language {
  padding: 4px 0;
  font-size: 12px;
  width: 24px;
  outline: none;
}

.button__icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  line-height: 0;
  transition: transform 0.2s ease-out;
}

.button__icon_no-margin {
  margin: 0;
}

.button__text {
  vertical-align: middle;
}

.button__count {
  background: #c2297f;
  border-radius: 4px;
  color: #fff;
  font-size: 0.8em;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  padding: 2px 0;
}

.button_split {
  border-radius: 4px 0 0 4px;
}

.button_tooltip-toggle {
  border-radius: 0 4px 4px 0;
  padding: 0 5px 0 5px;
  position: relative;
  // ÐÐ°ÐµÐ·Ð¶Ð°ÐµÐ¼ Ð½Ð° ÑÐ°Ð¼ÐºÑ ÑÐ¾ÑÐµÐ´Ð½ÐµÐ¹ ÐºÐ½Ð¾Ð¿ÐºÐ¸
  left: -1px;
}

.button_show-tooltip-toggle .button__icon {
  transform: rotate(180deg);
}

.button_input {
  position: absolute;
  top: 3px;
  right: 3px;
  bottom: 3px;
  padding-top: 0;
  padding-bottom: 0;
  background: $button-input-background;
  border-color: $button-input-background;
}

.button_cell-action {
  border-color: transparent;
  background: transparent;
  padding: 5px;
  display: flex;
  fill: $table-action-button-color;
}
