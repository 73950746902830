@import "values";

.content {
  display: flex;
  flex-grow: 1;
  margin-left: 110px;
  margin-right: 30px;
  flex-direction: column;
}

.content__head {
  padding-top: 30px;
}

.content__title {
  font-size: 1.8rem;
  margin: 0 0 30px 0;
}

.content__subtitle {
  color: $gray-text-color;
  font-weight: normal;
  font-size: 0.9em;
}

.content__body {
  display: flex;
  flex-grow: 1;
}

.content__footer {
  color: $gray-text-color;
  font-size: 12px;
  text-align: right;
  margin-top: auto;
}

.content__footer-text {
  border-top: 1px solid $border-color-light;
  margin-top: 35px;
  margin-right: -$table-offset;
  margin-left: -$table-offset;
  width: 100%;
  padding: 3px 0;
  user-select: none;
}
