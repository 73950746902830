@import "values";

@mixin select-multi-color($color) {
  $border-value-color: lighten($color, 30%);
  color: $color;
  background-color: lighten($color, 45%);
  border-color: $border-value-color;

  .Select-value-icon {
    border-right-color: $border-value-color;

    &:hover {
      background-color: lighten($color, 40%);
      color: inherit;
    }
  }
}

.order-status__value {
  vertical-align: middle;
}

.order-status__value-icon {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: $status-none;
  margin: 0 8px 0 2px;
  border-radius: 10px;
  vertical-align: middle;
  position: relative;
  top: -1px;
}

.order-status__value-icon_disabled {
  background-color: $status-disabled-color;
  opacity: 0.5;
}

.order-status__value-icon_yellow {
  background-color: $status-yellow-color;
}

.order-status__value-icon_green {
  background-color: $status-green-color;
}

.order-status__value-icon_purple {
  background-color: $status-purple-color;
}

.order-status__value-icon_gray {
  background-color: $status-gray-color;
}

.order-status__value-icon_red {
  background-color: $status-red-color;
}

.Select--multi .Select-value_color-yellow {
  @include select-multi-color($status-yellow-color);
}

.Select--multi .Select-value_color-green {
  @include select-multi-color($status-green-color);
}

.Select--multi .Select-value_color-purple {
  @include select-multi-color($status-purple-color);
}

.Select--multi .Select-value_color-gray {
  @include select-multi-color($status-gray-color);
}

.Select--multi .Select-value_color-red {
  @include select-multi-color($status-red-color);
}
