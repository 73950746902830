.auth {
  height: 100%;
  padding: 120px 100px;
  margin: auto;
}

.auth__title {
  position: absolute;
  top: -60px;
  text-align: center;
  left: 0;
  right: 0;
}
