.controls-group {
  display: flex;
  align-items: baseline;
}

.controls-group_form {
  margin: 0 20px 5px;
}

.controls-group__control {
  & + & {
    margin-left: 15px;
  }
}

.controls-group_column {
  flex-direction: column;

  .controls-group__control + .controls-group__control {
    margin-left: 0;
    margin-top: 10px;
  }
}
