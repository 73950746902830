@import "values";

.support-info {
  margin-bottom: 22px;
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 1px;
    position: absolute;
    top: -5px;
    bottom: -5px;
    left: -10px;
    background: $border-color-light;
  }
}

.support-info__item {
  display: flex;
  line-height: 1.4em;
  justify-content: space-between;

  & + & {
    margin-top: 10px;
  }
}

.support-info__value {
  flex-shrink: 0;
  padding-left: 10px;
}
