@import "values";

.license-form__license {
  position: relative;

  &:before {
    content: '';
    background: $border-color-dark;
    width: 2px;
    display: block;
    position: absolute;
    top: 0;
    left: -15px;
    bottom: 24px;
  }
}

.license-form__header {
  padding-bottom: 20px;
  margin-left: 20px;
}

.license-form__title {
  font-size: 1.5rem;
  margin-right: 5px;
  font-weight: bold;
  display: inline-block;
}

.license-form__subtitle {
  color: $gray-text-color;
  font-weight: normal;
  font-size: 0.9em;
}
