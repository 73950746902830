@import "values";
// react-tabs https://github.com/reactjs/react-tabs

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 1px solid $tab-border-color;
  margin: 0 0 10px;
  padding: 0;
  user-select: none;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 4px 4px 0 0;
  transition: all 0.5s ease;

  &:focus {
    box-shadow: none;
    border-color: $tab-focus-border-color;
    background: $tab-focus-background;
    outline: none;

    &:after {
      content: "";
      position: absolute;
      height: 5px;
      left: -4px;
      right: -4px;
      bottom: -5px;
      background: #fff;
    }
  }
}

.react-tabs__tab--selected {
  border-color: $tab-border-color;
  background: $tab-background;

  &:hover {
    border-color: $tab-hover-border-color;
  }

  &:focus:hover {
    border-color: $tab-focus-border-color;
  }
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}
