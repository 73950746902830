@import "values";

@mixin chip-color($color) {
  color: $color;
  background: lighten($color, 45%);

  &:before {
    $background-chip-color: lighten($color, 45%);
    background: linear-gradient(to right, rgba($background-chip-color, 0), $background-chip-color);
  }
}

.chip {
  padding: 1px 7px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 14px;
    height: 100%;
    right: 0;
  }
}

.chip_yellow {
  @include chip-color($status-yellow-color);
}

.chip_green {
  @include chip-color($status-green-color);
}

.chip_purple {
  @include chip-color($status-purple-color);
}

.chip_gray {
  @include chip-color($status-gray-color);
}

.chip_red {
  @include chip-color($status-red-color);
}

.chip_none {
  color: $default-color;
  background: $status-none;

  &:before {
    background: linear-gradient(to right, rgba($status-none, 0), $status-none);
  }
}
