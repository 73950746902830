@import "values";

.generated-info {
  display: flex;
  width: 100%;
  margin: 0 0 0 20px;
}

.generated-info__value {
  color: $dark-gray-text-color;
  padding-left: 5px;
}
