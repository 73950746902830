$main-background: #f6f5f3;
$default-color: #333;

$status-none: #f5f5f5;
$status-disabled-color: #afafaf;
$status-yellow-color: #ff8f00;
$status-green-color: #7cb342;
$status-gray-color: #757575;
$status-purple-color: #ab47bc;
$status-red-color: #d62222;

$gray-text-color: #bdbdbd;
$dark-gray-text-color: #6d6d6d;

$line-color: $main-background;

$gray-icon-color: #bdbdbd;

$link-color: #B12D21;
$link-hover-color: #D94F24;

$border-color-active: #3d7add;
$border-color-dark: #e5e5e5;
$border-color-light: #ececec;

$field-border-color: #e5e5e5;
$field-placeholder-color: #b0b0b0;
$field-disabled-color: #999;
$field-disabled-border-color: #e5e5e5;
$field-disabled-background: #f3f3f3;
$field-error-color: #c02880;
$field-error-border-color: #c02880;

$button-color: #333;
$button-background: #fff;
$button-border-color: $field-border-color;
$button-hover-border-color: #bdbdbd;
$button-focus-border-color: $border-color-active;
$button-active-border-color: $border-color-active;
$button-active-background: #f5f8ff;

$button-submit-color: #fff;
$button-submit-background: #B12D21;
$button-submit-focus-background: #D94F24;
$button-submit-active-background: #8B251C;

$button-currency-background: #f6f5f3;

$button-currency-active-color: $button-submit-color;
$button-currency-active-background: $button-submit-background;
$button-currency-active-focus-background: $button-submit-focus-background;
$button-currency-active-active-background: $button-submit-active-background;

$button-input-background: #f6f5f3;

$checkbox-color: $border-color-active;
$checkbox-border-color: #e5e5e5;
$checkbox-hover-border-color: #bdbdbd;
$checkbox-focus-border-color: $border-color-active;
$checkbox-active-border-color: $border-color-active;
$checkbox-active-background: #e2ebff;
$checkbox-disabled-color: #a5a7aa;
$checkbox-disabled-border-color: $field-disabled-border-color;
$checkbox-disabled-background: $field-disabled-background;

$tab-background: #f6e0ee;
$tab-border-color: #B12D21;
$tab-hover-border-color: #D94F24;
$tab-focus-border-color: #8B251C;
$tab-focus-background: #ffdef3;

$form-auth-border-color: #e6e6e6;

$sidebar-menu-background: #353434;
$sidebar-menu-item-color: #e9e5f5;
$sidebar-menu-item-hover-color: #f0ecfc;
$sidebar-menu-item-active-background: #232121;

$empty-table-text-color: $gray-text-color;

$table-header-border-color: $line-color;
$table-header-hover-color: $link-hover-color;
$table-body-hover-background: #f2f4f8;
$table-row-border-color: $line-color;
$table-action-button-color: #6d6d6d;

$toasts-info-background: #323232;
$toasts-warning-background: #ef6c00;
$toasts-error-background: #ef5350;

$pop-up-menu-item-color: #B12D21;
$pop-up-menu-item-muted-color: #9e96b7;
$pop-up-menu-item-hover-background: #eeecf4;

$user-menu-icon-background: #232121;

$modal-base-color: #B12D21;
$modal-background: rgba($modal-base-color, 0.45);
$modal-content-background: #fff;
$modal-close-button-color: $modal-base-color;
$modal-close-button-hover-background: rgba($modal-base-color, 0.15);
$modal-close-button-focus-background: rgba($modal-base-color, 0.3);

$preloader-color: #B12D21;

$table-columns: 24;
$table-offset: 12px;
$table-action-button-width: 30px;

$form-field-width: 280px;

$form-control-padding: 8px 0 24px 0;
$filter-control-padding: 8px 0 15px 0;

$required-asterisk-color: #d62222;
