.era-functionality {}

.era-functionality__column {
  flex-grow: 1;
}

.era-functionality__title {
  font-size: 1.4rem;
  font-weight: bold;
  display: inline-block;
  margin: 15px 20px;
}