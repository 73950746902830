@import "values";

.sidebar {
  position: fixed;
  display: flex;
  background: $sidebar-menu-background;
  height: 100%;
  width: 80px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 1;
}
