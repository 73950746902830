.drives {
  display: flex;
}

.drives__input {
  flex: 1 0 auto;
}

.drives__checkbox {
  margin: 13px;
}
