@import "values";

.sticky-icon {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.sticky-icon__text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sticky-icon__icon {
  flex-shrink: 0;
  margin-left: 5px;
  display: flex;
  align-items: baseline;
}

.sticky-icon__icon_gray {
  fill: $gray-icon-color;
}