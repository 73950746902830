@import "values";

.license-text {
  height: 350px;
  width: 650px;
  border: 1px solid $border-color-dark;
  padding: 5px 10px;
  line-height: 1.4rem;
  font-family: "RobotoMono Regular", monospace;
  border-radius: 4px;

  &:focus {
    outline: none;
    border-color: $border-color-active;
  }
}
