@import "values";

.modal__layout {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  overflow-y: scroll;
  padding: 5% 0;
}

.modal-enter .modal__layout {
  opacity: 0.01;
  padding-top: 3%;
}

.modal-enter.modal-enter-active .modal__layout {
  opacity: 1;
  padding-top: 5%;
  transition: opacity 300ms ease-in-out, padding 300ms ease-in-out;
}

.modal-leave .modal__layout {
  opacity: 1;
  padding-top: 5%;
}

.modal-leave.modal-leave-active .modal__layout {
  opacity: 0.01;
  padding-top: 3%;
  transition: opacity 200ms ease-in-out, padding 200ms ease-in-out;
}

.modal__wrapper {
  padding: 10px 100px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal__wrapper_wide {
  align-items: stretch;
  flex-direction: column;
}

.modal__title {
  font-size: 24px;
  font-weight: 500;
}

.modal__header {
  margin-bottom: 25px;
}

.modal__content {
  background: $modal-content-background;
  max-width: 100%;
  padding: 24px;
  border-radius: 10px;
}

.modal__footer {
  display: flex;
  justify-content: flex-end;
}

.modal__footer-buttons {
  margin: 30px -20px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.modal__close-button {
  position: fixed;
  width: 64px;
  height: 64px;
  top: 50px;
  right: 50px;
  z-index: 101;
  color: $modal-close-button-color;
  border-radius: 100%;
  text-align: center;
  cursor: pointer;
  user-select: none;
  outline: none;
  border: none;
  background: transparent;
  transition: all 0.3s ease;

  &:hover {
    background: $modal-close-button-hover-background;
  }

  &:active,
  &:focus {
    background: $modal-close-button-focus-background;
  }

  &:active {
    transform: scale(0.9, 0.9);
  }
}

.modal__close-button-icon {
  width: 100%;
  display: block;
  fill: $modal-base-color;
}

.modal__close-button-text {
  font-size: 12px;
}

.modal__fake-input {
  position: absolute;
  opacity: 0;
  height: 0;
}
