@import "values";
@import "mixins";

.support-period-component {
  margin: $form-control-padding;
  display: flex;
}

.support-period-component__period {
  flex: 1 0 auto;
}

.support-period-component__day {
  width: 60px;
  margin-left: 10px;
  position: relative;
}

.support-period-component__day-label {
  top: -28px;
  position: absolute;

  @include required-asterisk;
}
