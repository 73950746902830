@import "values";

.back-link {
  text-decoration: none;
  display: inline-block;
  color: $gray-text-color;
  margin-bottom: 13px;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: color 0.5s ease;

  &:focus {
    outline: 2px solid $border-color-active;
    outline-offset: 3px;
  }

  &:hover {
    color: $link-hover-color;
  }

  &:active {
    outline: none;
  }
}
