@import "values";

.error-page {
  margin: auto;
}

.error-page__container {
  display: flex;
  align-items: center;
}

.error-page__icon {
  margin-right: 40px;
  line-height: 0;
}

.error-page__text_big {
  font-size: 62px;
  line-height: 1em;
  margin-bottom: 15px;
}

.error-page__text_normal {
  color: $dark-gray-text-color;
  font-size: 16px;
  margin-bottom: 5px;
  max-width: 500px;
}

.error-page__text_small {
  color: $dark-gray-text-color;
}

.error-page__footer {
  margin-top: 25px;
}