@import "values";

@font-face {
  font-family: "Roboto Regular";
  src: url("../fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto Medium";
  src: url("../fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: "RobotoMono Regular";
  src: url("../fonts/RobotoMono-Regular.ttf");
}

html, body {
  background: #fff;
  width: 100%;
  height: 100%;
  font-family: "Roboto Regular", sans-serif;
  font-size: 13px;
  color: $default-color;
  line-height: 1.65em;
  min-width: 1024px;
}

label[for] {
  cursor: pointer;
}

textarea {
  resize: vertical;
  height: 100px;
  min-height: 100px;
}

a {
  color: $link-color;

  &:hover {
    color: $link-hover-color;
  }
}

* {
  box-sizing: border-box;
}

#root {
  min-height: 100%;
  display: flex;
}

.app {
  min-height: 100%;
  display: flex;
  flex-grow: 1;
}

.link {
  text-decoration: underline;
  user-select: none;
}

.link_dashed {
  text-decoration: none;
  border-bottom: 1px dashed;
}

.link_separated {
  & + & {
    margin: 0 10px;
  }
}

.link_disabled,
.link_disabled:hover {
  color: $field-disabled-color;
  cursor: default;
}

.gray-text {
  color: $dark-gray-text-color;
}
